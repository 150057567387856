import React, { useState, useCallback } from 'react';
import { SpeakersData } from './speakersdata';
import Modal from 'react-bootstrap/Modal';
// alter_form_password
import Card from 'react-bootstrap/Card';
import { Row, Col, Container, Button } from 'react-bootstrap';
import SpeakerModal from './speakermodal';
import AlterForm from './alterform';
import styles from '../App.module.css'
import { FaLinkedin } from "react-icons/fa"
import { AiFillLinkedin } from "react-icons/ai"


function Speakers() {
  const [modalShow, setModalShow] = useState(false);
  const [speaker, setSpeaker] = useState({});
  const [form, setForm] = useState(false)

  const newForm = useCallback(
    (e) => {
      setSpeaker(true)
    },
    [form]
  );


  return (
    <div data-section className={styles.eventSpeakers} id="speakers">
      <div>
        <h1 className={styles.speakersTitle} onDoubleClick={() =>
          setSpeaker(true)}>SPEAKERS</h1>
        {/* <hr
          style={{
            margin: '5px auto 65px',
            padding: 0,
            maxWidth: 250,
            border: 0,
            borderTop: 'solid 5px',
            textAlign: 'center',
            color: '#fff'
          }}
        /> */}
      </div>
      <Container>
        <Row>
          {SpeakersData.map((item) => (
            <Col sm={6} md={4} lg={3} key={item.name} style={{ paddingBottom: '2rem' }}>
              <div
                // onClick={() => {
                //   setSpeaker(item);
                //   setModalShow(true);
                // }}
                key={Math.random()}
                className={styles.speakerCard}
              >
                <div
                  // style={{ width: '14rem', color: '#fff' }}
                  className={styles.speaker}
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    objectFit="contain"
                    className={styles.speakerImg} />
                </div>

                <div style={{
                  paddingBottom: '20px', color: '#fff', alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: '10%',
                  textAlign: 'center'
                }}>
                  <div>
                    <h2 style={{ fontWeight: "bolder" }}>{item.name} </h2>
                    <p style={{ marginTop: '-12px', marginBottom: 0 }}>{item.company}</p>
                  </div>
                </div>
              </div>
              {item.linkedIn !== "#" && (
                <div>
                  <a href={item.linkedIn} style={{
                    paddingBottom: '20px', alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    // paddingLeft: '10%',
                    textAlign: 'center'
                  }} target="_blank">
                    <FaLinkedin size={20} />
                  </a>
                </div>

              )}



            </Col>
          ))}
        </Row>
        <SpeakerModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          content={speaker}
        />
        <AlterForm
          show={form}
          onHide={() => setForm(false)}
          content={speaker}

        />
      </Container>
    </div >
  );
}

export default Speakers;
