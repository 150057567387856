import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import styles from '../App.module.css';


import styles from '../App.module.css'

import { SponsorsImg } from './speakersdata';

const Sponsors = () => {
  return (
    <>
      <main className={styles.sponsors} id="sponsors">
        <Container id="about">
          <Row xs={2} md={4}>
            {SponsorsImg.map((item, idx) => (
              <Col key={idx}>
                <img
                  src={item}
                  alt={'sponsors_image'}
                  width={100}
                  height={100}
                  className={styles.sponsorsImg}
                  layout="fixed"
                  style={{ objectFit: 'contain' }}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </main>
      {/* <hr className={styles.sponsorsHr} /> */}
    </>
  );
};

export default Sponsors;
