import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, Button } from 'react-bootstrap';
import styles from './App.module.css';
import NavBar from './pages/NavBar';
import About from './pages/about';
import Sponsors from './pages/sponsors';
import Speakers from './pages/speakers';
import VenueAndAccomodation from './pages/venueandaccomodation';
import OrganizingCommiteeGrid from './pages/organizationcommitee';
import AccomodationDetails from './pages/hotel';
import Agenda from './pages/agenda';
import Registration from './pages/registration';
import Footer from './pages/footer';

export default function App() {
  return (
    <div>
      <NavBar />
      <main data-section id="home" className={styles.landingPage}>
        <Container>
          <div className="jumbotron">
            <div className={styles.pageIntroductoryText}>
              <h1 style={{
                fontSize: '3.25em',
                fontWeight: 'bold',
                marginTop: '-3em',
                marginBottom: "1em"
              }}>IEEE Workshop On Leveraging 5G for Vertical Industries </h1>
              <div className={styles.hrHolderTop}>
                <span>
                  <hr className={styles.addressHRTop}></hr>
                </span>
              </div>

              <h4>Oct 4th, 2023</h4>
              <h4>
                Rutgers University Inn and Conference Center <br />
                178 Ryders Lane<br />
                New Brunswick, New Jersey
              </h4>

              <div className={styles.hrHolderTop}>
                <span>
                  <hr className={styles.addressHRBottom}></hr>
                </span>
              </div>
              <Button className={styles.agendaBtn}>
                <a href="docs/Agenda-verticals-v5.pdf" target="_blank" style={{ textDecoration: "none", color: 'inherit' }} className={styles.atagbtn} type="application/pdf" rel="alternate" media="print">Agenda</a>
              </Button>
              <Button className={styles.registerbtn}> <a href='https://events.vtools.ieee.org/m/371110' target='_blank' className={styles.regbtn}>  Register Now</a></Button>
            </div>
          </div>
        </Container>
      </main>
      <About />
      <Sponsors />
      <Speakers />
      <AccomodationDetails />
      <OrganizingCommiteeGrid />
      <Agenda />
      {/* <Registration /> */}
      <Footer />
    </div>
  );
}