import NavLink from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
// import styles from '../App.css'


const NavBar = () => {
    const [activeSection, setActiveSection] = useState(null);
    const sections = useRef([]);

    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;

        sections.current.forEach((section) => {
            // let offsetTop = section.getBoundingClientRect().top;
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (
                pageYOffset >= sectionOffsetTop &&
                pageYOffset < sectionOffsetTop + sectionHeight
            ) {
                newActiveSection = section.id;
            }
        });

        setActiveSection(newActiveSection);
    };

    // useEffect(() => {
    //     sections.current = document.querySelectorAll('[data-section]');

    //     // console.log('Current ', sections.current);
    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);



    return (
        <>
            <Navbar
                variant="dark"
                sticky="top"
                expand="sm"
                collapseOnSelect
                style={{
                    backgroundColor: '#2c3e50',
                    color: "#fff"
                }}
            >
                <Container>
                    <Navbar.Brand>
                        <p style={{
                            fontWeight: "bold"
                        }}
                        >Leveraging 5G for Vertical Industries</p>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="main-navbar" />
                    <Navbar.Collapse id="main-navbar">
                        <Nav className="ms-auto">
                            <Nav.Link
                                href="/"
                                className={activeSection === 'home' ? 'active' : ''}
                            >
                                <p style={{ fontWeight: "bold", color: "#fff" }}>
                                    Home
                                </p>
                            </Nav.Link>
                            <Nav.Link href="#about" className={activeSection === 'about' ? 'active' : ''}>
                                <p style={{ fontWeight: "bold", color: "#fff" }}>
                                    About
                                </p>
                            </Nav.Link>
                            <Nav.Link href="#speakers" className={activeSection === 'speakers' ? 'active' : ''} >
                                <p style={{ fontWeight: "bold", color: "#fff" }}>
                                    Speakers
                                </p>
                            </Nav.Link>
                            <Nav.Link
                                href="#venue"
                                className={activeSection === 'venue' ? 'active' : ''}>
                                <p style={{ fontWeight: "bold", color: "#fff" }}>
                                    Venue
                                </p>
                            </Nav.Link>
                            <Nav.Link
                                href="#agenda"
                                className={activeSection === 'agenda' ? 'active' : ''}
                            >
                                <p style={{ fontWeight: "bold", color: "#fff" }}>
                                    Agenda
                                </p>
                            </Nav.Link>
                            <Nav.Link
                                href="#registration"
                                className={activeSection === 'registration' ? 'active' : ''}
                            >
                                <p style={{ fontWeight: "bold", color: "#fff" }}>
                                    Registration
                                </p>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default NavBar;
