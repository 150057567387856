import React from 'react';
import styles from '../App.module.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Hotels } from './speakersdata';
import { AccomodationInfo } from './speakersdata';
import Background from "../img/rutgers-university-inn.jpeg"
const AccomodationDetails = () => {
  return (
    <section
      className={styles.venueSection}
      id="venue"
    >
      <Container>
        <Row>
          <Col>
            <h2 style={{ textAlign: 'left', paddingLeft: '50px', fontSize: '3em' }}>Venue</h2>
            <div style={{
              margin: '2em auto',
              height: '0.1rem',
              background: '#fff'
            }}>
              <span>
                <hr className={styles.addressHRTop}></hr>
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Rutgers University Inn and Conference Center</strong><br />
            178 Ryders Lane<br />
            New Brunswick. NJ 08901
          </Col>
          <Col lg={9}>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d6064.013972590554!2d-74.4736598!3d40.5414348!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x89c3c75fadf3ef5d%3A0x3dc297e7877b93d7!2sEricsson%20%E3%80%9208854%20New%20Jersey%2C%20Piscataway%20Ericsson%20Dr!3m2!1d40.5414868!2d-74.4734892!5e0!3m2!1sen!2sus!4v1690001865887!5m2!1sen!2sus"
              width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen=""></iframe> */}

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3034.6459023969387!2d-74.4308685!3d40.48309810000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c640f3cb9db5%3A0x4f367532040bea3e!2sRutgers%20University%20Inn%20and%20Conference%20Center!5e0!3m2!1sen!2sus!4v1695250104443!5m2!1sen!2sus"
              width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Col>
        </Row>
        <div class="row">
          <div id="venue-image-credit"></div>
        </div>
      </Container>
    </section>
  );
};

export default AccomodationDetails;

