import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import styles from '../App.module.css'

const Agenda = () => {
  return (
    <main data-section className={styles.agenda} id="agenda">
      <Container style={{ alignSelf: "center", justifyContent: "center", textAlign: "center" }} >
        {/* <Row> */}
        <h1 className={styles.agendaTitle}>Agenda</h1>
        <hr style={{
          margin: '-8px auto 65px',
          padding: 0,
          maxWidth: 250,
          border: 0,
          borderTop: 'solid 5px',
          textAlign: 'center',
          color: '#0a332b'
        }} />
        <Image src={require("../docs/vert.png")} />
      </Container>

    </main>
  );
};

export default Agenda;
