import React from 'react';
import styles from '../App.module.css'
import { OrganizingCommiteeInfo, StreeringCommittee, StaffSupport } from './speakersdata';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Row, Col, Container } from 'react-bootstrap';
import SpeakerModal from './speakermodal';

function OrganizingCommitee() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div data-section id="OrganizingCommitee" className={styles.committeeBg}>
      <div className={styles.committee}>
        <h1 className={styles.committeeTitles}>Organizing Committee</h1>
        <hr style={{
          margin: '-21px auto 65px',
          padding: 0,
          maxWidth: 400,
          border: 0,
          borderTop: 'solid 5px',
          textAlign: 'center',
          color: '#fff'
        }} />
      </div>
      <Container>
        <Row style={{ paddingBottom: '15%', justifyContent: "center" }}>
          {OrganizingCommiteeInfo.map((item) => (
            <Col sm={6} md={4} lg={3} key={item.committee + item.name} style={{ color: "#fff", textAlign: "center" }}>
              <Card.Title style={{ marginBottom: 12 }}>{item.position}</Card.Title>
              {/* <Card.Img variant="top" src={item.img} alt={item.name} /> */}
              <img src={item.img} alt={item.name} />
              <Card.Title>{item.name}</Card.Title>
              <Card.Text>{item.company}</Card.Text>
              <SpeakerModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                content={item}
              />
            </Col>
          ))}
        </Row>
        <h1
          // className={styles.speakersTitle}
          className={styles.committeeTitles}
        >
          Steering Committee
        </h1>
        <hr style={{
          margin: '-21px auto 65px',
          padding: 0,
          maxWidth: 400,
          border: 0,
          borderTop: 'solid 5px',
          textAlign: 'center',
          color: '#fff'
        }} />
        <Row
          style={{
            justifyContent: "center",
            paddingBottom: '12%',
          }}
        >
          {StreeringCommittee.map((item) => (
            <Col sm={6} md={4} lg={3} key={item.name} style={{
              color: "#fff", textAlign: "center", textAlign: "center",
              justifyContent: "center",
              // margin: "auto"
            }}>

              <img src={item.img} alt={item.name} />
              <Card.Title>{item.name}</Card.Title>
              <p>{item.position}</p>

              <SpeakerModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                content={item}
              />
            </Col>
          ))}
        </Row>

        <h1
          className={styles.committeeTitles}
        >
          Staff Support
        </h1>
        <hr style={{
          margin: '-21px auto 65px',
          padding: 0,
          maxWidth: 300,
          border: 0,
          borderTop: 'solid 5px',
          textAlign: 'center',
          color: '#fff'

        }} />
        <Row style={{
          justifyContent: "center",
        }}
        >
          {StaffSupport.map((item) => (
            <Col sm={6} md={4} lg={3} key={item.name} style={{
              color: "#fff", textAlign: "center",
              justifyContent: "center",
              // color: '#fff',
              margin: "auto"
            }}>

              <img src={item.img} alt={item.name} />
              <Card.Title>{item.name}</Card.Title>
              <p>{item.position}</p>

              <SpeakerModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                content={item}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default OrganizingCommitee;
