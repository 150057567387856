import JohnDoe from '../img/speakers/AllenKatz.jpg';
import John from '../img/speakers/AnwarWalid.jpg';
import PHI from '../img/speakers/ph.jpg'
import AndreasMueller from '../img/speakers/AndreasMueller.jpeg'
import NevinJones from '../img/speakers/NevinJones.jpeg'
import FrodeStoldal from '../img/speakers/FrodeStoldal.jpeg'
import Milind from '../img/speakers/MilindBuddhikot.jpeg'
import FlavioBonomi from "../img/speakers/FlavioBonomi.jpeg"
import CarlosLabbate from '../img/speakers/carloslabbate.jpeg'
import DarrylDeaton from '../img/speakers/DarrylDeaton.jpeg'
import EricLHeureux from '../img/speakers/EricLHeureux.jpeg'
import SyedZaeemHosain from '../img/speakers/SyedZaeemHosain.jpeg'
import RyanPettijohn from '../img/speakers/RyanPettijohn.jpeg'
import RudraDutta from '../img/speakers/RudraDutta.jpg'
import RussellVest from '../img/speakers/RussellVest.jpeg'
import BillBeesley from '../img/speakers/BillBeesley.jpeg'
import KerenRonen from '../img/speakers/KerenRonen.jpeg'
import AdamSchipper from '../img/speakers/AdamSchipper.jpeg'
import IvanSeskar from '../img/speakers/IvanSeskar.jpeg'
import JorgePereira from "../img/speakers/JorgePereira.jpeg"
// sponsors
import IEEEPrinceton from '../img/sponsors/ieee-pcj.jpg';
import ComSoc from '../img/sponsors/comsoc.png';
import Future from '../img/sponsors/New-FNI-Logo.png';
import Region from '../img/sponsors/ieee-region1.jpg';
import TV from '../img/sponsors/ieeetv_logo.png';

//Committee
import Kataria from '../img/committee/DeepakKataria.jpg'
import Egoh from '../img/committee/KomlanEgoh.jpg'
import Akouvi from '../img/committee/akouvi.jpeg'
import Oconnell from '../img/committee/FranOConnell.jpg'
import AshutoshDutta from '../img/committee/AshutoshDutta.jpg'
import Galli from '../img/committee/StefanoGalli.jpg'
import MolorKar from "../img/committee/MoloyKar.jpeg"
import RogerDing from "../img/committee/RogerDing.jpeg"
import BradKloza from "../img/committee/BradKloza.jpeg"
import TonyBennet from '../img/committee/tonybennet.jpeg'
import Place from "../img/ph.jpg"
import CraigPolk from '../img/committee/CraigPolk.jpeg'


export const SponsorsImg = [IEEEPrinceton, ComSoc, Future, TV];
export const SpeakersData = [
  {
    name: 'Dr.Andreas Mueller',
    company: 'Head of Communication and Network Technology & Chief Expert for Communication Technologies IoT, Robert Bosch GmbH | Bosch',
    img: AndreasMueller,
    role: 'Head of Communication and Network Technology & Chief Expert for Communication Technologies IoT, Robert Bosch GmbH',
    about: "Dr. Andreas Mueller is a renowned expert at the forefront of connectivity research and 5G/6G innovation. As the leader of Bosch's strategic 6G activities across diverse business units and sites, he spearheads holistic transformative endeavors aligned with business imperatives. With his profound expertise in communication technologies for the IoT, Andreas also serves as the Chief Expert in this field. Moreover, he has held the position of General Chair of 5G-ACIA, the globally leading organization driving and shaping Industrial 5G, since its establishment in 2018. Andreas’ pivotal contributions also include coordinating and advancing Bosch's Industrial 5G undertakings over the last couple of years, focusing on key areas such as private networks, Open RAN, AI/ML, and edge computing. Equipped with a robust background in telecommunications and vertical industry applications, Andreas is uniquely positioned to drive the transformative 5G/6G-enabled revolution across various sectors.",
    talkTitle: '',
    talkBody: '',
    linkedIn: "https://www.linkedin.com/in/andreas-mueller-6g/"
  },
  {
    name: 'Frode Stoldal',
    company: 'President | Tampnet Americas',
    img: FrodeStoldal,
    role: 'President',
    about: "",
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/frodestoldal/'
  },
  {
    name: 'Keren Ronen',
    company: 'Director of Alliances and Business Development | Ericsson',
    img: KerenRonen,
    role: 'Director of Alliances and Business Development',
    about: "",
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/keren-ronen-b499219/'
  },
  {
    name: 'Syed Zaeem Hosain',
    company: 'Founder, Chief Evangelist | Aeris Communications, Inc.',
    img: SyedZaeemHosain,
    role: 'Founder, Chief Evangelist',
    about: 'Intro',
    talkTitle: '5G in the verizon network architecture',
    talkBody: 'body',
    linkedIn: 'https://www.linkedin.com/in/syedzaeemhosain/'
  },

  {
    name: 'Carlos L’Abbate',
    company: 'CTO | Anterix',
    img: CarlosLabbate,
    role: 'CTO',
    about: 'Intro',
    talkTitle: '5G in the verizon network architecture',
    talkBody: 'body',
    linkedIn: 'https://www.linkedin.com/in/labbate/'
  },

  {
    name: 'Adam Schipper',
    company: 'Ericsson-Private Networks',
    img: AdamSchipper,
    role: 'Director of Business Development',
    about: "",
    linkedIn: 'https://www.linkedin.com/in/adam-schipper-ctp-6943233/'
  },


  {
    name: 'Rudra Dutta',
    company: 'Professor | North Carolina State University',
    img: RudraDutta,
    role: 'Vice President',
    about:
      'Rudra Dutta received a B.E.in Electrical Engineering from Jadavpur University, Kolkata, India, in 1991, a M.E.in Systems Science and Automation from Indian Institute of Science, Bangalore, India in 1993, and a Ph.D.in Computer Science from North Carolina State University, Raleigh, USA, in 2001. From 1993 to 1997 he worked for IBM as a software developer and programmer in various networking related projects.He has been employed from 2001 - 2007 as Assistant Professor, from 2007 - 2013 as Associate Professor, and since 2013 as Professor, in the department of Computer Science at the North Carolina State University, Raleigh. \n His current research interests focus on design and performance optimization of large networking systems, Internet architecture, wireless networks, and network analytics.He is a senior member of IEEE and a distinguished member(distinguished engineer) of ACM.His work has been supported by grants from the National Science Foundation, the Army Research Office, the National Security Agency, and industry, most recently including a PAWR grant from NSF(the AERPAW project).Over the years, he has served as a reviewer for many premium journals, on NSF, DoE, ARO, and NSERC(Canada) review panels, and as part of the organizing committee of many premium conferences.',
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/rudra-dutta-1126a02/'
  },

  {
    name: 'Eric L’Heureux',
    company: 'President, CEO and founder | Solutions Ambra Inc.',
    img: EricLHeureux,
    role: 'President, CEO and founder',
    about: "",
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/elheureux'
  },
  {
    name: 'Nevin Jones',
    company: 'Private 5G technical lead | Verizon',
    img: NevinJones,
    role: 'Private 5G technical lead',
    about: `Nevin Jones is a Distinguished Solution Architect on the Complex 4G LTE/5G Solutions Engineering team at Verizon Wireless in Basking Ridge New Jersey.He is responsible for engaging with major enterprises
and organizations to solution engineer their connectivity and associated edge computing needs.In this capacity, he conducts RF planning and design of dedicated indoor and/ or outdoor private cellular
networks across a broad range of vertical segments. Nevin’s previous professional tenures was respectively at Bell Labs in Murray Hill NJ and at ZTE (Shanghai, China) where he worked on ASIC and line card module designs for cellular networking and
optical transport and switching equipment.` + '\n' + `He is an alumnus of City University of New York and received MPhil, MA & amp; PhD degrees in engineering.`,
    talkTitle: '',
    talkBody: '',
    linkedIn: '#'
  },
  {
    name: 'Flavio Bonomi',
    company: 'Senior Solutions Architect and SME, Intelligent Edge Practice | Accenture',
    img: FlavioBonomi,
    role: 'Technology Advisor',
    about: '',
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/flavio-bonomi-phd/'
  },

  {
    name: 'Bill Beesley',
    company: 'Fujitsu',
    img: BillBeesley,
    role: 'Principal Wireless Solutions Architect',
    about: `Bill Beesley’s decades-long tenure in the telecommunications industry both as an operator and as a
supplier, have given him deep insider knowledge of the real-world challenges operators face as they
tackle unprecedented operational and technical transformation. He combines hands-on experience with
strategic whole-industry vision of the art, architecture and science of communications networking.
Driven by operational and practical wisdom as much as by technological expertise, Bill extends the
discussion beyond the alphabet soup of the day and into the operational demands of constant use.
Whether it’s Open RAN, Open ROADM, PON, or DOCSIS, he provides key insights into simplified, efficient
network operations and durable performance. Bill holds a B.S. in IT Management, an M.S. in
Telecommunications, and multiple patents related to the networking industry. When he isn’t developing
practical network solutions for operators, you might find Bill promoting bicycling as the Director of
Education for Bike DFW, teaching as a certified instructor for the League of American Bicyclists, brewing
beer, or taking long hikes with his Catahoula Leopard dog Bentley. In the words of musician Maynard
James Keenan, you might call him a Perfect Union of Contrary Things.`,
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/bill-beesley-%E3%83%93%E3%83%AB-%E3%83%93%E3%83%BC%E3%82%B9%E3%83%AC%E3%82%A4-2073682/'
  },
  {
    name: 'Milind Buddhikot, Ph.D.',
    company: 'Nokia Bell Labs Fellow',
    img: Milind,
    role: 'Head of NDAC NJ Technology Center & Head of NDAC E2E Spectrum Solutions',
    about: '',
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/milind-buddhikot-b67784/'
  },

  {
    name: 'Darryl A Deaton',
    company: 'VP Network Solutions & Enterprise Sales | Bearcom',
    img: DarrylDeaton,
    role: 'VP‑Network Solutions & Enterprise Sales',
    about: '',
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/darryl-deaton-b8146843/'
  },

  {
    name: 'Russell Vest',
    company: 'CTO | Ballast Networks',
    img: RussellVest,
    role: 'CTO',
    about: 'Russell has 30+ years of driving fundamental changes in the telecommunications industry covering the range from network design and deployment, development of the leading third- party operator to establishing industry best practices and standards.Based on this foundation of change he is committed to delivering connectivity solutions for the enterprise by leveraging advanced technologies toward greater user - control that integrates seamlessly into global networks.He is an active member of HTNG chairing the CBRS Workgroup extending Private LTE knowledge to the hospitality industry.Russell holds a BS degree in Electrical Engineering from Southern Methodist University and is a member of the IEEE Communications Society.',
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/russell-vest-a50a101a/'
  },

  {
    name: 'Ivan Seskar',
    company: 'Chief Technologist at WINLAB | Rutgers University',
    img: IvanSeskar,
    role: 'CTO',
    about: '',
    talkTitle: '',
    talkBody: '',
    linkedIn: 'https://www.linkedin.com/in/ivan-seskar/'
  },

  {
    name: 'Dr.Jorge Pereira',
    company: 'Principal Scientific Officer | European Commission',
    img: JorgePereira,
    role: '',
    about: '',
    talkTitle: '',
    talkBody: '',
    linkedIn: "https://www.linkedin.com/in/jorge-pereira-0b180210/"
  },



];

export const Hotels = [
  {
    hotelName: 'Venue:Rutgers university inn and conference center',
    hotelLocation: '178 Ryders Ln, New Brunswick, NJ 08901',
    hotelPhone: '(732) 932-9144',
    hotelWebsite: 'https://inn.rutgers.edu/',
  },
  {
    hotelName: 'Embassy Suites by Hilton',
    hotelLocation: '121 Centennial Ave.',
    hotelPhone: '+1-732-980-0500',
    hotelWebsite:
      'https://www.hilton.com/en/hotels/pisnjes-embassy-suites-piscataway-somerset/hotel-info/',
    hotelDistanceFromEvent: 'This hotel is 2.7 miles away from the venue',
  },
  {
    hotelName: 'Homewood Suites by Hilton Somerset',
    hotelLocation: '101 Pierce Street, Somerset, NJ 08873',
    hotelPhone: '+1-732-868-9155',
    hotelWebsite:
      'https://www.hilton.com/en/hotels/fkthwhw-homewood-suites-somerset/hotel-location/',
    hotelDistanceFromEvent:
      'This hotel is approximately 5 miles away from the workshop venue',
  },
  {
    hotelName: 'The Heldrich Hotel',
    hotelLocation: '10 Livingston Ave, New Brunswick, NJ 08901 USA',
    hotelPhone: '+1-732-729-4670',
    hotelWebsite: '',
    hotelDistanceFromEvent:
      'This hotel is approximately 5 miles away from the workshop venue',
  },

];

export const AccomodationInfo = [
  {
    accomodation: 'Nearest Local Airport and Public Transportation',
    details:
      'The closest international airport is Newark Liberty International Airport, International Air Transport Association (IATA) code EWR (https://www.panynj.gov/airports/newark-liberty.html). Taxis are available outside the airport terminals. The distance between the workshop venue and EWR is 27-30 miles, depending on route. The drive time can range between 30 minutes and one hour, depending on traffic conditions.',
  },
  {
    accomodation: 'Taxis in the Piscataway area',
    details:
      'The following taxi services are available to travel from the hotels to sites in the Piscataway, Somerset, and New Brunswick, New Jersey area. 1. Yellow Cab: +1 732-246-2222 2. Checkered Cab: +1-732-545-2300',
  },
  {
    accomodation: 'Limo Service from Newark Liberty International Airport',
    details: `Hurley Limousine (+1-908-654-9000) is available for passenger pick-up from Newark Liberty 
    International Airport to the following cities.
1. Piscataway, New Jersey = $68.00 USD plus fuel surcharge for that day
2. New Brunswick, New Jersey = $79.00 USD plus fuel surcharge for that day
3. Somerset, New Jersey = $80.00 USD plus fuel surcharge for that day

Reservations must be made in advance of travel date.
New Brunswick, New Jersey Local Information
New Brunswick is just a few miles away from the workshop venue. New Brunswick is home to Rutgers University, the state university of New Jersey. According to https://www.visitnj.org/city/new-brunswick,
In the 15-block downtown New Brunswick area, there are nearly 50 restaurants.`,
  },
  {
    accomodation: 'New York City Information',
    details:
      'It is about 50 minutes by car under low traffic conditions from Piscataway to New York City. New York City attractions can be found at https://www.nycgo.com/.',
  },
];

export const OrganizingCommiteeInfo = [
  {
    position: 'Workshop Chair and Director of Organizing Committee', name: 'Deepak Kataria', company: 'Princeton Central Jersey Section', img: Kataria
  },
  {
    position: 'Private Networks Role Model Volunteer', name: 'Moloy Kar', company: 'Ericsson', img: MolorKar
  },

  { position: 'Mission Critical Networks Role Model Volunteer', name: 'Anthony Bennett', company: 'Ericsson ', img: TonyBennet },
];

export const StreeringCommittee = [
  { position: 'IEEE Chair,PCJS', name: 'Roger Ding', img: RogerDing, linkedIn: "" },
  { position: 'Director Industry Outreach/ Co-Chair IEEE Future Networks', name: 'Ashutosh Dutta', img: AshutoshDutta },

  // { position: 'Director Industry Outreach/ Co-Chair IEEE Future Networks', name: 'Ashutosh Dutta', img: AshutoshDutta, linkedIn: "" },
  // { position: 'Princeton Central Jersey Section', name: 'Fran O’Connell', img: Oconnell },
  // { position: 'Vice President, IEEE ComSoc', name: 'Stefano Galli', img: Galli },
  // { position: 'Princeton Central Jersey Section', name: 'Fran O’Connell', img: Oconnell },


];
export const StaffSupport = [
  { position: 'Program Director, IEEE', name: 'Brad Kloza', img: BradKloza, linkedIn: "" },
  { position: 'Program Manager, IEEE Future Networks', name: 'Craig Polk', img: CraigPolk, linkedIn: "" },
  { position: 'Website Chair', name: 'Akouvi Kouedjin', company: 'New Dew ', img: Akouvi },


];
