import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from '../App.module.css'

const About = () => {
  return (
    <main data-section id="about" style={{ background: '#d8ddde', marginTop: '-5%' }}>
      <Container>
        <Row className={styles.aboutText}>
          <Col>
            <p className={styles.aboutNameStart}>
              5G has emerged as a key enabler of digitalization in vertical
              industries. This has opened transformative opportunities in different
              sectors such as manufacturing, energy, utilities, ports, mining,
              transportation, public safety, agriculture to name a few that seek to
              optimize operations and lowering OpEx, offer new capabilities, improve
              safety and enhance sustainability.
            </p>
            <p>
              The successful deployment of 5G in vertical applications requires
              close collaboration between vertical industries, and an ecosystem of
              technology providers, system integrators, business analysts and CSPs.
              Motivated by these trends and advances in 5G to foster industrial
              innovation, IEEE workshop on 5G in Vertical Industries is bringing
              together leading stakeholders from across the entire ecosystem to
              discuss opportunities and challenges.  </p>
            <p>
              Workshop will cover a myriad of topics to include spectrum
              alternatives for private networks, CSP provided network slicing for
              private network use cases, evolution of 5G CPE ecosystem and their
              management, 5G core in a box for private networks, public/private
              interworking, role of SD-WAN for enterprise connectivity in private
              networks, utility of edge computing and exposure APIs to enable a rich
              suite of private network offerings.
            </p>
            <p>
              This single-day workshop is sponsored by IEEE Princeton Central Jersey
              section with IEEE Future Networks and IEEE Communication Society as
              technical co-sponsors and will provide a unique opportunity to share
              and learn about 5G use cases in vertical industries.
            </p>
          </Col>

        </Row>
      </Container>
    </main>
  );
};

export default About;
