import React from 'react';
import styles from '../App.module.css'

const Footer = () => {
  return (
    <div>
      <div className={styles.footerDiv}>
        <span>
          Copyright © IEEE
          Princeton Central Jersey Section 2023-2024 Send
        </span>
        <br />
        <span>comments/suggestions to komlan [at[ njit [dot] edu</span>
      </div>
    </div>
  );
};

export default Footer;
