import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

import styles from '../App.module.css'

import {
    JSX,
    DetailedHTMLProps,
    HTMLAttributes,
    RefObject,
    ReactNode,
} from 'react';
import { Omit, BsPrefixProps } from 'react-bootstrap/esm/helpers';

const AlterForm = (
    props
) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ opacity: 1, }}
        >
            <Container style={{ background: '#d8ddde' }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.content.name}
                        <span style={{ fontSize: 12, color: '#A21F1F' }}>
                            {props.content.company}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ float: 'left', padding: '2rem' }}>
                        <img
                            src={props.content.img}
                            alt={props.content.name}
                            objectFit="contain"
                        />
                    </div>

                    <div>
                        <h4>{props.content.talkTitle}</h4>
                        <p>{props.content.about}</p>
                        <p style={{ color: '#A21F1F' }}>Talk</p>

                        <h3> {props.content.talkTitle}</h3>
                        <p>{props.content.talkBody}</p>
                    </div>
                </Modal.Body>
            </Container>
        </Modal>
    );
};
export default AlterForm;

